import { ApolloError } from '@graphcommerce/graphql'
import axios from 'axios'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useState } from 'react'

export type CallAPIProps = {
  url: string
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  contentType?: string
  headers?: Record<string, unknown>
}

// Hook
const useCallAPI = (props: CallAPIProps) => {
  const { url, method, contentType = 'application/json', headers } = props
  const [error, setError] = useState<Error | ApolloError | undefined>()
  const [response, setResponse] = useState<any>()
  const [loading, setIsLoading] = useState<boolean>(false)

  const apiCall = async (body?: any) => {
    setIsLoading(true)
    const res = await axios
      .request({
        headers: { 'Content-Type': contentType, ...headers },
        method,
        url,
        data: body ?? '',
      })
      .catch((e: Error) => {
        setError(e)
      })
    if (res?.status === 200 || res?.status === 201 || res?.status === 202 || res?.status === 204) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setResponse(res)
      setIsLoading(false)
      return res
    }
    setIsLoading(false)
    return null
  }

  return { error, response, apiCall, loading }
}
export default useCallAPI
